export const apiUrl = process.env.API_URL ?? `https://${window.location.hostname}`;

declare const Telegram: {
    WebApp: {
        initData: string;
    };
};

export function commonHeaders(): any {
    return {
        'InitData': Telegram.WebApp.initData as string
    };
}