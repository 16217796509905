import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PackList from './PackList';

declare const Telegram: {
  WebApp: {
    initData: string;
  };
};

const HomePage = () => {
  return (
    <div>
      <PackList />
    </div>
  );
};


export default HomePage;