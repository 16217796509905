import React, { useEffect, useState } from 'react';
import { ScrollView, useColorScheme } from 'react-native';
import styles from './styles';
import PackCell from './PackCell';
import { useNavigate } from 'react-router-dom';
import { apiUrl, commonHeaders } from './misc';

interface Pack {
    identifier: string;
    isSelected: boolean;
    thumbnailURL?: string;
    name: string;
}


const PackList = () => {
    const [packs, setPacks] = useState<Pack[]>([]);
    const colorScheme = useColorScheme(); // Get the current color scheme (light/dark)
    const navigate = useNavigate();

    useEffect(() => {
        fetchPacks().then((packs) => {
            setPacks(packs);
        });
    }, []);

    const isDarkMode = colorScheme === 'dark'; // Check if dark mode is active

    const handlePackClick = async (id: string): Promise<void> => {
        console.log(`NAVIGATE TO: /pack/${id}`)
        navigate(`/pack/${id}`);
    };

    return (
        <ScrollView contentContainerStyle={[styles(isDarkMode).packs, { backgroundColor: isDarkMode ? '#000' : '#fff' }]}>
            {packs.map((item) => (
                <PackCell
                    key={item.identifier}
                    item={item}
                    isDarkMode={isDarkMode}
                    handlePackClick={handlePackClick}
                />
            ))}
        </ScrollView>
    );
};

const fetchPacks = async (): Promise<Pack[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/myPacks`, {
            method: 'GET',
            headers: commonHeaders(),
        });
        if (!response.ok) {
            alert(response.status)
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data: Pack[] = await response.json();
        return data
    } catch (error) {
        console.error('Error fetching packs:', error);
        return []
    }
};

export default PackList;
