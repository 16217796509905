import React from "react";
import { View, Text, Image, StyleSheet } from 'react-native';

interface StickerProps {
    title: string;
    image: string;
}

const PackItemCell: React.FC<StickerProps> = ({ title, image }) => {
    return (
        <View style={styles.container}>
            <Image source={{ uri: image }} style={styles.image} />
            <Text style={styles.text}>{title}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        margin: 10,
    },
    image: {
        width: 100,
        height: 100,
        marginBottom: 5,
    },
    text: {
        color: 'white',
    },
});

export default PackItemCell;
