import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AboutPage from './App/AboutPage';  // Ensure correct path
import HomePage from './App/HomePage';  // Ensure correct path
// import PackList from './App/PackList';
import PackPage from './App/PackPage';


const App = () => {
  loadTelegramInDebug()

  return (
    <div style={style()}>
      <Router>
        <Routes>
          <Route path='/' element={<HomePage />} />  {/* HomePage route */}
          <Route path="/about" element={<AboutPage />} />  {/* AboutPage route */}
          <Route path="/pack/:id" element={<PackPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;


const style = () => {
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return {
    backgroundColor: isDarkMode ? '#000' : '#fff',
    minHeight: '100vh',
    color: isDarkMode ? '#fff' : '#000',
  };
};


function loadTelegramInDebug() {
  if (process.env.NODE_ENV === 'development') {
    // Inject the initData from the .env file into the window object
    window.Telegram = {
      WebApp: {
        initData: process.env.INIT_DATA,
        // Add other properties and methods you need
      }
    };

    const initData = process.env.INIT_DATA;
    if (initData) {
      Telegram.WebApp.initData = initData;
      // console.log("Injected initData for Telegram WebApp in development mode:", Telegram.WebApp.initData);
    } else {
      console.log("no initData")
    }
  }
  // console.log("env:" + process.env.NODE_ENV)
}