import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, ActivityIndicator } from 'react-native';
import PackItemCell from './PackItemCell';  // The PackItem component
import { apiUrl, commonHeaders } from './misc';
import { useParams } from 'react-router-dom';

interface PackPageInfo {
    id: string;
}

interface PackItemModel {
    name: string;
    identifier: number | null;
    fileURL: string | null;
}

interface PackResponseModel {
    name: string;
    identifier: string | null;
    thumbnailURL: string | null;
    items: PackItemModel[];
}

const PackPage: React.FC<PackPageInfo> = () => {
    const { id } = useParams();  // Get the id from the path
    const [packItems, setPackItems] = useState<PackItemModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    console.log(`id ${id}`)
    // Fetch the data from the API
    const fetchPackItems = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/pack?id=${id}`, {
                method: 'GET',
                headers: commonHeaders()
            });
            const data: PackResponseModel = await response.json();
            setPackItems(data.items);
        } catch (error) {
            console.error('Error fetching pack items:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPackItems();
    }, []);

    const renderPackItem = ({ item }: { item: PackItemModel }) => (
        <PackItemCell title={item.name} image={item.fileURL || ''} />
    );

    if (loading) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#ffffff" />
            </View>
        );
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'black', padding: 20 }}>
            <Text
                style={{ fontSize: 24, color: 'white', marginBottom: 20 }}
                selectable={true}
            >
                Pack: {id}
            </Text>
            <FlatList
                data={packItems}
                renderItem={renderPackItem}
                keyExtractor={item => item.identifier?.toString() || item.name}
                numColumns={3}  // Adjust this if you want a grid layout
            />
        </View>
    );
};

export default PackPage;
