import React from 'react';
import { TouchableOpacity, View, Text, Image } from 'react-native';
import styles from './styles';

interface PackItem {
    isDarkMode: boolean;
    item: {
        identifier: string;
        thumbnailURL?: string;
        name: string;
        isSelected?: boolean;
    };
    handlePackClick: (identifier: string) => void;
}

const PackCell: React.FC<PackItem> = ({ isDarkMode, item, handlePackClick }) => (
    <TouchableOpacity
        key={item.identifier}
        style={[styles(isDarkMode).pack, item.isSelected && styles(isDarkMode).selectedPack]}
        onPress={() => handlePackClick(item.identifier)}
    >
        <View style={styles(isDarkMode).thumbnail}>
            {item.thumbnailURL && <Image source={{ uri: item.thumbnailURL }} style={styles(isDarkMode).image} />}
        </View>
        <Text style={styles(isDarkMode).title}>{item.name}</Text>
    </TouchableOpacity>
);

export default PackCell;
